<template>
  <div class="log-okr">
    <div class="okr-title" :class="{ 'okr-title-close': !isDropOkr }">
        <span
          class="okr-name"
          :class="{ 'left-scroll-okr-name': leftScroll, 'active-text-color': isHover }"
          @click="dropDownHandle"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
        >
          <span class="okr-name-title">目标</span>
          <!-- 左侧滚动时，使用Tooltip组件 -->
          <template v-if="leftScroll">
            <Tooltip
              max-width="100%"
              :lines="1"
              :content="okrDataComputed.name"
              placement="top" />
          </template>
          <template v-else>
            {{ okrDataComputed.name }}
          </template>
        </span>
        <img
          v-if="!leftScroll"
          src="@/assets/img/work/work-drop-okr.svg"
          alt="drop-okr"
          class="drop-okr"
          :class="{ 'hover-drop-svg': isHover }"
          @click="dropDownHandle"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
        >
        <div class="okr-schedule">
          <ProgressCircle :schedule="okrDataComputed?.schedule || 0" :size="24" />
          <span>{{ okrDataComputed?.schedule || 0 }} %</span>
        </div>
    </div>
    <transition name="fade">
      <div v-show="isDropOkr">
        <template v-for="(item, index) in okrDataComputed.key_results" :key="index">
          <div class="okr-content">
            <OkrItem
              :item="item"
              :index="index"
              :templateType="OKR_STATUS_TYPE.PASS"
              :disabled="isHistory"
              :okrList="okrDataComputed.key_results"
              :showWeight="false"
              :shouldEllipsis="leftScroll"
              :showScheduleToast="false"
              @editSchedule="editSchedule"
            />
            <template v-if="isHistory">
              <div class="okr-content-history">
                <template v-if="!leftScroll">
                  <span>进展：</span>
                </template>
                <div v-html="item?.content"></div>
              </div>
            </template>
            <template v-else>
              <div class="okr-content-wrapper">
                <span>进展：</span>
                <div>
                  <WriteEditorJournal
                  :ref="el => writeEditorJournalRefs[index] = el"
                  :id="'okr_' + index"
                  :content="item?.content || ''" />
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </transition>

  </div>
</template>

<script>
import { computed, ref } from 'vue'
import OkrItem from '@/views/layout/work/okr/myokr/component/OkrItem.vue'
import ProgressCircle from '@/views/layout/work/okr/myokr/component/ProgressCircle.vue'
import { OKR_STATUS_TYPE } from '@/utils/constant.js'
import WriteEditorJournal from './WriteEditorJournal.vue'

export default {
  name: 'LogOkr',
  props: {
    isHistory: {
      type: Boolean,
      default: false
    },
    okrData: {
      type: Object,
      default: () => ({
        name: '',
        schedule: 0,
        key_results: []
      })
    },
    leftScroll: {
      type: Boolean,
      default: false
    }
  },
  components: { OkrItem, ProgressCircle, WriteEditorJournal },
  emits: ['editSchedule'],
  setup (props, { emit }) {
    const okrDataComputed = computed(() => props.okrData)
    const writeEditorJournalRefs = ref([])
    const isDropOkr = ref(true)
    const isHover = ref(false)

    const dropDownHandle = () => {
      if (!props.leftScroll) {
        isDropOkr.value = !isDropOkr.value
        const toolbar = document.querySelectorAll('.w-e-toolbar')
        toolbar.length && (toolbar[toolbar.length - 1].style.display = 'flex')
      }
    }

    const getOkrContent = () => {
      const keyResults = writeEditorJournalRefs.value.map((el, index) => (
        Object.assign(
          okrDataComputed.value.key_results[index], // 原okr内容
          { file_name: `KR${index + 1}`, content: el?.getHtml() || '', is_okr: true, index } // okr评论内容
        )
      ))

      const okrData = {
        file_name: okrDataComputed.value.name,
        name: okrDataComputed.value.name,
        schedule: okrDataComputed.value.schedule,
        id: okrDataComputed.value.id,
        is_okr: true,
        content: keyResults.map(item => item.content).join('\n'), // 用于判断是否显示内容
        key_results: keyResults
      }
      return okrData
    }

    const editSchedule = () => {
      let allSchedule = 0
      okrDataComputed.value.key_results.forEach(item => {
        allSchedule += Math.floor((item.weights * item.schedule / 100))
      })
      emit('editSchedule', allSchedule, okrDataComputed.value.key_results[0].objective_id)
    }
    return {
      okrDataComputed,
      OKR_STATUS_TYPE,
      getOkrContent,
      writeEditorJournalRefs,
      isDropOkr,
      dropDownHandle,
      editSchedule,
      isHover
    }
  }
}
</script>

<style scoped lang="less">
.log-okr {
  .okr-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @border-color;
    padding-bottom: 10px;
    margin-bottom: 12px;

    &.okr-title-close {
      margin-bottom: 32px;

      .drop-okr {
        transform: rotate(180deg);
        transition: transform 0.5s ease;
      }
    }

    & > span {
      font-weight: 700;
      font-size: 16px;
    }

    .okr-name {
      flex: 1;
      min-width: 0;
      display: flex;
      align-items: center;
      cursor: pointer;

      .okr-name-title {
        width: 38px;
        min-width: 38px;
        height: 20px;
        line-height: 20px;
        font-size: 13px;
        color: #fff;
        background-color: @active-text-color;
        border-radius: 6px;
        text-align: center;
        margin-right: 8px;
      }
    }

    div {
      :deep(.el-progress) {
        margin-right: 10px;
      }

      span {
        display: inline-block;
        color: @active-text-color;
        font-size: 13px;
        min-width: 32px;
        text-align: right;
      }
    }
  }

  :deep(.target-item) {
    margin-bottom: 8px;
    border-bottom: 0;

    .target-item-left > .item-kr-content {
      font-weight: 700;
      min-height: 18px;
    }

    .item-progress {
      margin-left: 20px;
    }

    .target-item-left {
      align-items: flex-start;
      transform: translateY(2px);

      .item-kr-content {
        padding: 0;
        flex: 1;
        line-height: 19px;
      }

      img {
        display: none;
      }

      span {
        margin-left: 0;
      }
    }
    .item-progress {
      align-items: flex-start;
      .el-input-number {
        margin: 0 4px;
      }

      .el-progress, span {
        transform: translateY(3px);
      }
    }
  }

  :deep(.editor-el) {
    min-height: 50px;
    padding-bottom: 24px;
  }

  .okr-content {
    border-bottom: 1px solid @border-color;
    margin-bottom: 8px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;

      :deep(.editor-el) {
        padding-bottom: 0;
      }
    }
  }

  .okr-content-history {
    margin-bottom: 24px;
    display: flex;
    span {
      min-width: 42px;
    }
  }

  .okr-schedule {
    margin-left: 16px;
  }

  .left-scroll-okr-name {
    display: flex;

    :deep(.text-ellipsis) {
      flex: 1;
      min-width: 0;
    }
  }
}

.drop-okr {
  cursor: pointer;
}

.hover-drop-svg {
  filter: invert(56%) sepia(72%) saturate(1242%) hue-rotate(218deg) brightness(99%) contrast(94%);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
  max-height: 1000px;
  overflow: hidden;
}

.fade-enter-from,
.fade-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.okr-content-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;

  span {
    font-size: 14px;
  }

  div {
    flex: 1;
    min-width: 0;
  }
}
</style>
